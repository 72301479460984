import React, { Component } from 'react'
import { useSelector } from 'react-redux'
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import './scss/style.scss'
import 'react-toastify/dist/ReactToastify.css'
import ErrorBoundary from './library/utils/error-boundary'

const loading = (
    <div className="pt-3 text-center">
        <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
)

const UnauthenticatedRoute = ({ component: Component, ...rest }) => {
    const isAuthenticated = useSelector((state) => state.apiToken) !== null
    return (
        <Route
            {...rest}
            render={(props) =>
                !isAuthenticated ? (
                    <Component {...props} />
                ) : (
                    <Redirect to="/" />
                )
            }
        />
    )
}

const AuthenticatedRoute = ({ component: Component, ...rest }) => {
    const isAuthenticated = useSelector((state) => state.apiToken) !== null
    return (
        <Route
            {...rest}
            render={(props) =>
                isAuthenticated ? (
                    <Component {...props} />
                ) : (
                    <Redirect to="/login" />
                )
            }
        />
    )
}

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'))
const Login = React.lazy(() => import('./views/user/Login'))
const Register = React.lazy(() => import('./views/user/Register'))
const RegisterSuccess = React.lazy(() => import('./views/user/RegisterSuccess'))
const LwaAuthorization = React.lazy(() =>
    import('./views/onboarding/LwaAuthorization')
)
const VerifyEmail = React.lazy(() => import('./views/user/VerifyEmail'))
const Imprint = React.lazy(() => import('./views/legal/Imprint'))
const Privacy = React.lazy(() => import('./views/legal/Privacy'))
const SupportLandingpage = React.lazy(() =>
    import('./views/legal/SupportLandingpage')
)
const ResetPasswordRequest = React.lazy(() =>
    import('./views/user/ResetPasswordRequest')
)
const ResetPasswordChange = React.lazy(() =>
    import('./views/user/ResetPasswordChange')
)

class App extends Component {
    render() {
        return (
            <>
                <ErrorBoundary>
                    <HashRouter>
                        <React.Suspense fallback={loading}>
                            <Switch>
                                <UnauthenticatedRoute
                                    exact
                                    path="/login"
                                    name="Login Page"
                                    component={Login}
                                />
                                <UnauthenticatedRoute
                                    exact
                                    path="/register"
                                    name="Register Page"
                                    component={Register}
                                />
                                <UnauthenticatedRoute
                                    exact
                                    path="/register-success"
                                    name="Register Success"
                                    component={RegisterSuccess}
                                />
                                <UnauthenticatedRoute
                                    exact
                                    path="/verify-email"
                                    name="Verify Email"
                                    component={VerifyEmail}
                                />
                                <UnauthenticatedRoute
                                    exact
                                    path="/reset-password/request"
                                    name="Request password reset"
                                    component={ResetPasswordRequest}
                                />
                                <UnauthenticatedRoute
                                    exact
                                    path="/reset-password/change"
                                    name="Change password"
                                    component={ResetPasswordChange}
                                />
                                <UnauthenticatedRoute
                                    exact
                                    path="/imprint"
                                    name="Imprint"
                                    component={Imprint}
                                />
                                <UnauthenticatedRoute
                                    exact
                                    path="/privacy"
                                    name="Privacy"
                                    component={Privacy}
                                />
                                <Route
                                    exact
                                    path="/settings/onboarding/auth/lwa"
                                    name="LWA Authorization"
                                    component={LwaAuthorization}
                                />
                                <Route
                                    exact
                                    path="/help"
                                    name="Help"
                                    component={SupportLandingpage}
                                />
                                <AuthenticatedRoute
                                    path="/"
                                    name="Home"
                                    component={TheLayout}
                                />
                            </Switch>
                        </React.Suspense>
                    </HashRouter>
                    <ToastContainer autoClose={6000} />
                </ErrorBoundary>
            </>
        )
    }
}

export default App
