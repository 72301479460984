import { sygnet } from './sygnet'
import { logo } from './logo'
import { logoNegative } from './logo-negative'

import {
    cilApplicationsSettings,
    cilSpeedometer,
    cilSun,
    cilMoon,
    cilBan,
    cilChartLine,
    cilLockLocked,
    cilSpeech,
    cilLineWeight,
    cilTv,
    cilCheckAlt,
    cilX,
} from '@coreui/icons'

export const icons = Object.assign(
    {},
    {
        sygnet,
        logo,
        logoNegative,
    },
    {
        cilApplicationsSettings,
        cilSpeedometer,
        cilSun,
        cilMoon,
        cilBan,
        cilChartLine,
        cilLockLocked,
        cilSpeech,
        cilLineWeight,
        cilTv,
        cilCheckAlt,
        cilX,
    }
)
