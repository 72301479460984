import React from 'react'
import { CCol, CContainer, CRow } from '@coreui/react'

const MainErrorPage = () => {
    return (
        <div className="c-app c-default-layout flex-row align-items-center">
            <CContainer>
                <CRow className="justify-content-center">
                    <CCol md="6">
                        <span className="clearfix">
                            <h1 className="float-left display-3 mr-4">500</h1>
                            <h4 className="pt-3">
                                Houston, we have a problem!
                            </h4>
                            <p className="text-muted float-left">
                                Lade bitte die Seite neu oder versuche es zu
                                einem späteren Zeitpunkt.
                            </p>
                        </span>
                        <a className="btn btn-info" href="/">
                            zur Startseite
                        </a>
                    </CCol>
                </CRow>
            </CContainer>
        </div>
    )
}

export default MainErrorPage
