import { createStore } from 'redux'
import { loadState, saveState } from './stores/sessionStore'

const initialState = {
    sidebarShow: 'responsive',
    asideShow: false,
    darkMode: false,
    user: null,
    apiToken: null,
    merchantId: null,
    onboardingStep: null,
}

const changeState = (state = initialState, { type, ...rest }) => {
    switch (type) {
        case 'set':
            return { ...state, ...rest }
        default:
            return state
    }
}

const persistedState = loadState()
const store = createStore(changeState, persistedState)

store.subscribe(() => {
    saveState(store.getState())
})

export default store
